<template>
  <!--  -->
  <Layout>
    <div class="main-order-detail">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        
        <!-- 订单状态 -->
        <div class="md-od-1 c-card" v-ani.fade-up>
          <div class="hd s16 color-9">
            <span>订单编号：{{ detailInfo.order_sn }}</span>
            <span class="split">|</span>
            <span>下单时间：{{ detailInfo?.add_time }}</span>
          </div>
          <div class="bd flex-between">
            <div class="lf flex-1">
              <div class="status s22 font-medium">{{ detailInfo.order_status_desc }}</div>
              <!-- <div class="tips s14 color-9">卖家部分已发货，请耐心等待</div> -->
              <div class="opes flex-middle flex-wrap">
                <BaseButton size='large' class="btn-ope" v-if="detailInfo.pay_btn == 1" color='red' @click="toPay">去支付</BaseButton>
                <BaseButton size='large' class="btn-ope" color='gray' v-if="detailInfo.cancel_btn == 1"  @click="toCancel">取消订单</BaseButton>
                <!-- <BaseButton size='large' class="btn-ope">催审</BaseButton> -->
                <BaseButton size='large' class="btn-ope" v-if="detailInfo.receive_btn == 1" @click="toOrderReceive">确认收货</BaseButton>
                <BaseButton size='large' class="btn-ope" v-if="detailInfo.remind_btn == 1" @click="toUrgeGoods">催发货</BaseButton>
                <BaseButton size='large' class="btn-ope" v-if="detailInfo.comment_btn == 1" @click="toEvaluate">商品评价</BaseButton>
                <!-- <BaseButton size='large' class="btn-ope" style-type='border'>私聊留言</BaseButton>
                <BaseButton size='large' class="btn-ope" style-type='border'>私聊卖家</BaseButton> -->
                <!-- <BaseButton size='large' class="btn-ope" style-type='border' v-if="detailInfo.create_from == 'jdy' || detailInfo.pay_type == 2">下载合同</BaseButton>
                
                <el-upload
                  class="upload-demo"
                  :auto-upload="false"
                  :on-change="uploadContract">
                  <BaseButton size='large' class="btn-ope" style-type='border'>上传合同</BaseButton>
                </el-upload> -->
              </div>
              <!-- <div class="tips-2 s14">
                提示：商品中包含定制商品，卖家已延迟发货，预计发货时间2023/05/01日
              </div> -->
            </div>
            <div class="rg">
              <!-- 步骤列表 -->
              <OrderSteps :list='detailInfo.steps.items' :current="currentStep">
                <p>{{tips}}</p>
              </OrderSteps>
            </div>
          </div>
        </div>
        
        <!-- 发货记录 -->
        <div class="md-od-2 c-card" v-ani.fade-up id="logist" v-if="detailInfo.send_logs.length > 0">
          <div class="c-title-2 s22">发货记录</div>
          <div class="list">
            <div class="li" v-for="(item, i) in detailInfo.send_logs" :key="i">
              <div class="time s16 color-9">{{item.send_time}}</div>
              <div class="flex-middle">
                <div class="no s16 color-4">{{item.send_sn}}</div>
                <div class="t1 s14 color-red" v-if="item.reach_time_desc">{{item.reach_time_desc}}</div>
                <div class="state s12 color-red">{{item.receive_status_desc}}</div>
                <div class="btn s16 color-blue flex-center pointer" @click="toDeliveryNote">
                  <img src="@/assets/images/icon-change-2.png" alt="">
                  <span>{{ item.showText }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 订单明细 -->
        <div class="md-od-3 c-card" v-ani.fade-up>
          <div class="c-title-2 s22">订单明细</div>
          <!-- 基础信息 -->
          <div class="box">
            <div class="tit s18 color-2 font-medium">基础信息</div>
            <div class="list s16 color-9 flex-wrap">
              <div class="li" v-for="(item,i) in detailInfo.baseInfo" :key="i">
                <span class="t1">{{ item.key }}：</span>
                <span class="t2">{{ item.value }}</span>
              </div>
              <!-- <div class="li">
                <span class="t1">订单状态：</span>
                <span class="t2 color-red">待支付</span>
              </div>
              <div class="li">
                <span class="t1">支付方式：</span>
                <span class="t2 color-4">账期45天</span>
              </div> 
              <div class="li">
                <span class="t1">产品检测报告：</span>
                <span class="t2 color-4">需要</span>
              </div> 
              <div class="li">
                <span class="t1">订单编号：</span>
                <span class="t2 color-4">15656516516645</span>
              </div> 
              <div class="li">
                <span class="t1">付款方式：</span>
                <span class="t2 color-4">/</span>
              </div> 
              <div class="li">
                <span class="t1">备注：</span>
                <span class="t2 color-4">xxxxxx</span>
              </div> 
              <div class="li">
                <span class="t1">付款时间：</span>
                <span class="t2 color-4">2023-01-16 01:04:13</span>
              </div>  -->
            </div>
          </div>
          <div class="box flex-wrap">
            <!-- 收件人信息   -->
            <div class="box-item">
              <div class="tit s18 color-2 font-medium">收件人信息</div>
              <div class="list s16 color-9">
                <div class="li" v-for="(item,i) in detailInfo.baseInfo" :key="i">
                  <span class="t1">{{ item.key }}：</span>
                  <span class="t2">{{ item.value }}</span>
                </div>
                <!-- <div class="li">
                  <span class="t1">联系人：</span>
                  <span class="t2 color-4">张三</span>
                  <UserRank :type='3' :num='4'></UserRank>
                </div>
                <div class="li">
                  <span class="t1">履约能力：</span>
                  <span class="t2 color-4">易筑分650分</span>
                </div>
                <div class="li">
                  <span class="t1">手机号码：</span>
                  <span class="t2 color-4">15312220180</span>
                </div>
                <div class="li">
                  <span class="t1">所在地区：</span>
                  <span class="t2 color-4">广东省广州市503街道</span>
                </div>
                <div class="li">
                  <span class="t1">配送方式：</span>
                  <span class="t2 color-4">货拉拉09/11 14:00</span>
                </div> -->
              </div>
            </div>
            <!-- 发票信息 -->
            <div class="box-item">
              <div class="tit s18 color-2 font-medium">发票信息</div>
              <div class="list s16 color-9">
                <div class="li" v-for="(item,i) in detailInfo.invoiceInfo" :key="i">
                  <span class="t1">{{ item.key }}：</span>
                  <span class="t2">{{ item.value }}</span>
                </div>
                <!-- <div class="li">
                  <span class="t1">发票类型：</span>
                  <span class="t2 color-4">普通增值税发票</span>
                </div>
                <div class="li">
                  <span class="t1">发票抬头：</span>
                  <span class="t2 color-4">单位名称xoooxxx</span>
                </div>
                <div class="li">
                  <span class="t1">税号：</span>
                  <span class="t2 color-4">45516516541</span>
                </div>
                <div class="li">
                  <span class="t1">税点：</span>
                  <span class="t2 color-4">5%</span>
                </div>
                <div class="li">
                  <span class="t1">收票人：</span>
                  <span class="t2 color-4">李四</span>
                </div>
                <div class="li">
                  <span class="t1">收票人邮箱：</span>
                  <span class="t2 color-4">5151515@163.com</span>
                </div> -->
              </div>
            </div>
          </div>
          <!-- 收货人信息 -->
          <!-- <div class="box">
            <div class="tit s18 color-2 font-medium">收货人信息</div>
            <div class="list s16 color-9 flex-wrap">
              <div class="li">
                <span class="t1">采购类型：</span>
                <span class="t2 color-4">个人(按需展示)</span>
              </div>
              <div class="li">
                <span class="t1">收货人：</span>
                <span class="t2 color-4">张三</span>
              </div>
              <div class="li">
                <span class="t1">身份证图片：</span>
                <div class="imgs flex-middle">
                  <div class="img">
                    <img src="@/assets/images/idcard.jpg" alt="">
                  </div>
                  <div class="img">
                    <img src="@/assets/images/idcard.jpg" alt="">
                  </div>
                </div>
              </div>
              <div class="li">
                <span class="t1">联系电话：</span>
                <span class="t2 color-4">15312220180</span>
              </div>
              <div class="li">
                <span class="t1">身份证号码：</span>
                <span class="t2 color-4">12221213121321321</span>
              </div>
            </div>
          </div> -->
          <!-- 我的项目信息 -->
          <div class="box">
            <div class="tit s18 color-2 font-medium">我的项目信息</div>
            <div class="list s16 color-9 flex-wrap">
              <div class="li" v-for="(item,i) in detailInfo.projectInfoItems" :key="i">
                <span class="t1">{{ item.key }}：</span>
                <span class="t2">{{ item.value }}</span>
              </div>
              <div class="li">
                <span class="t1">营业执照：</span>
                <div class="imgs flex-middle">
                  <div class="img">
                    <!-- <img :src="detailInfo.projectInfoItemsLicenseImg"> -->
                    <el-image 
                      :src="detailInfo.projectInfoItemsLicenseImg" 
                      :preview-src-list="licenseImgList">
                    </el-image>
                  </div>
                </div>
              </div>
              <div class="li">
                <span class="t1">身份证图片：</span>
                <div class="imgs flex-middle">
                  <div class="img" v-for="(item,index) in detailInfo.projectInfoItemsIDCardImg" :key="index">
                    <!-- <img :src="item"> -->
                    <el-image 
                      :src="item" 
                      :preview-src-list="detailInfo.projectInfoItemsIDCardImg">
                    </el-image>
                  </div>
                </div>
              </div>
              <!-- <div class="li">
                <span class="t1">采购类型：</span>
                <span class="t2 color-4">企业(按需展示)</span>
              </div>
              <div class="li">
                <span class="t1">收货地址：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.project_address }}</span>
              </div>
              <div class="li">
                <span class="t1">身份证号码：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.id_card_no }}</span>
              </div>
              <div class="li">
                <span class="t1">业主单位：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.owner_unit }}</span>
              </div>
              <div class="li">
                <span class="t1">指定收货人：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.project_consignee }}</span>
              </div>
              <div class="li">
                <span class="t1">营业执照：</span>
                <div class="imgs flex-middle">
                  <div class="img" v-for="(item,index) in detailInfo.purchase_info.license_img" :key="index">
                    <img :src="item">
                  </div>
                </div>
              </div>
              <div class="li">
                <span class="t1">总包单位：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.winning_unit }}</span>
              </div>
              <div class="li">
                <span class="t1">收货人联系电话：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.project_phone }}</span>
              </div>
              <div class="li">
                <span class="t1">身份证图片：</span>
                <div class="imgs flex-middle">
                  <div class="img" v-for="(item,index) in detailInfo.purchase_info.id_card_img " :key="index">
                    <img :src="item">
                  </div>
                </div>
              </div>
              <div class="li">
                <span class="t1">工程名称：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.project_name }}</span>
              </div>
              <div class="li">
                <span class="t1">分包单位：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.shall_unit }}</span>
              </div>
              <div class="li">
                <span class="t1">购买方公司：</span>
                <span class="t2 color-4">{{ detailInfo.purchase_info.company_name }}</span>
              </div> -->
            </div>
          </div>
        </div>

        <!-- 付款记录 -->
        <div class="md-od-4 c-card" v-ani.fade-up v-if="detailInfo.pay_type == 2 && periodPayLogList.length > 0">
          <div class="c-title-2 s22">还款记录</div>
          <div class="cnt">
            <div class="list s16 color-9" v-for="(item,index) in periodPayLogList" :key="index">
              <div class="li">
                <span class="t1">交易号：</span>
                <span class="t2">{{ item.trade_no }}</span>
              </div>
              <div class="li">
                <span class="t1">实付金额：</span>
                <span class="t2">￥{{ item.pay_money }}</span>
              </div>
              <div class="li">
                <span class="t1">付款方式：</span>
                <span class="t2">{{ item.pay_way_desc }}</span>
              </div>
              <div class="li">
                <span class="t1">创建时间：</span>
                <span class="t2">{{ item.add_time }}</span>
              </div>
              <div class="li">
                <span class="t1">付款时间：</span>
                <span class="t2">{{ item.pay_time }}</span>
              </div>
              <div class="li">
                <span class="t1">支付状态：</span>
                <span class="t2">{{ item.status == 0 ? '待支付' : '已支付' }}</span>
              </div>
              <div class="li">
                <span class="t1">款项类型：</span>
                <span class="t2">{{ item.money_type_desc }}</span>
              </div>
              <div class="li" >
                <span class="t1">支付凭证：</span>
                <div class="imgs flex-middle">
                  <div class="img" v-if="item.pay_img">
                    <img :src="item.pay_img">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 商品信息 -->
        <div class="md-od-5 c-card" v-ani.fade-up>
          <div class="hd flex-middle flex-between">
            <div class="c-title-2 s22">商品信息</div>
            <div
              class="btn-complaint color-blue s16 pointer round flex-center"
              @click="dialog_complaint = true"
            >
              <img src="@/assets/images/icon-complaint.png" alt="">
              <span>投诉卖家</span>
            </div>
          </div>
          <!-- 已发货单 -->
          <!-- <div class="table s16 color-4">
            <div class="thead color-9 flex-middle">
              <div class="td">商品</div>
              <div class="td">单价</div>
              <div class="td">小计(元)</div>
              <div class="td">剩余发货数量</div>
              <div class="td">发货状态</div>
            </div>
            <div class="tbody">
              <div class="tr flex">
                <div class="td">
                  <router-link class="goods flex-middle s16 color-4" to="">
                    <img src="@/assets/images/prod-1.jpg" alt="" class="goods-cover">
                    <div class="text">
                      <div class="text-item">
                        <span class="color-9">商品标题：</span>
                        <span>泳池水下灯</span>
                      </div>
                      <div class="text-item">
                        <span class="color-9">规格：</span>
                        <span>50*50</span>
                      </div>
                    </div>
                    <div>x50</div>
                  </router-link>
                </div>
                <div class="td">￥668.00</div>
                <div class="td">668.00</div>
                <div class="td">/</div>
                <div class="td">已发货</div>
              </div>
            </div>
          </div> -->
          <!-- 其他单 -->
          <div class="table s16 color-4">
            <div class="thead color-9 flex-middle">
              <div class="td">商品</div>
              <div class="td">单价/数量</div>
              <!-- <div class="td">优惠(元)</div> -->
              <div class="td">小计(元)</div>
              <div class="td">剩余发货数量</div>
              <div class="td">发货状态</div>
            </div>
            <div class="tbody">
              <div class="tr flex" v-for="(item,index) in detailInfo.goods_list" :key="index">
                <div class="td">
                  <router-link class="goods flex-middle s16 color-4" to="">
                    <img :src="item.original_img" alt="" class="goods-cover">
                    <div class="text">
                      <div class="text-item">
                        <span class="color-9">商品标题：</span>
                        <span>{{ item.goods_name }}</span>
                      </div>
                      <div class="text-item">
                        <span class="color-9">规格：</span>
                        <span>{{ item.spec_key_name }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="td">
                  <div>
                    <p>￥{{ item.goods_price }}</p>
                    <p>{{ item.goods_num }}件</p>
                  </div>
                </div>
                <!-- 优惠 -->
                <!-- <div class="td">￥{{ item.coupon_price }}</div> -->
                <!-- 小计 -->
                <div class="td">￥{{ Math.floor(item.goods_num * item.goods_price).toFixed(2) }}</div>
                <!-- 剩余发货数量 -->
                <div class="td">{{ item.goods_num - item.send_count }}</div>
                <!-- 发货状态 -->
                <div class="td">
                  <span v-if="item.send_count == item.goods_num">已发货</span>
                  <span v-if="item.send_count > 0 && item.send_count < item.goods_num">部分发货</span>
                  <span v-if="item.send_count == 0">未发货</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list s16 color-9">
            <p>商品总价：￥{{ detailInfo.goods_price }}</p>
            <p>运费：￥{{ detailInfo.shipping_price }}</p>
            <p>优惠：￥{{ detailInfo.coupon_price }}</p>
            <p class="color-4 s20 font-medium">实收金额：￥{{ detailInfo.total_paid_amount }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- 删掉订单 -->
    <DialogOrderDelete
      v-if='dialog_delete'
      @cancel='dialog_delete = false'
      @confirm='deleteOrderConfirm'
    />
    <!-- 取消订单 -->
    <DialogOrderCancel
      v-if='dialog_cancel'
      @close='dialog_cancel = false'
      @cancel='dialog_cancel = false'
      @confirm='dialog_cancel = false'
      :orderId="detailInfo.order_id"  
    />
    <!-- 确认收货 -->
    <DialogOrderConfirm
      v-if='dialog_confirm'
      @close='dialog_confirm = false'
      @cancel='dialog_confirm = false'
      @confirm='receiveOrderConfirm'
      :orderId="detailInfo.order_id" 
    />
    <!-- 评价 -->
    <DialogOrderEvaluate
      v-if='dialog_evaluate'
      @close='dialog_evaluate = false'
      @cancel='dialog_evaluate = false'
      @confirm='elevateConfirm'
      :orderId="detailInfo.order_id" 
    />
    <!-- 买家退货发货 -->
    <DialogOrderSendGoods
      v-if="dialog_send_goods"
      @close='dialog_send_goods = false'
      @cancel='dialog_send_goods = false'
      @confirm='dialog_send_goods = false'
    />
    <!-- 发货信息 -->
    <DialogOrderSendGoodsMsg
      v-if="dialog_send_goods_msg"
      @close='dialog_send_goods_msg = false'
      @cancel='dialog_send_goods_msg = false'
      @confirm='dialog_send_goods_msg = false'
    />
    <!-- 商品反馈 -->
    <DialogOrderProdFeedback
      v-if="dialog_prod_feedback"
      @close='dialog_prod_feedback = false'
      @cancel='dialog_prod_feedback = false'
      @confirm='dialog_prod_feedback = false'
    />
    <!-- 投诉 -->
    <DialogOrderComplaint
      v-if="dialog_complaint"
      @close='dialog_complaint = false'
      @cancel='dialog_complaint = false'
      @confirm='confirmComplaint'
      :order_id="detailInfo.order_id" 
    />
    <!-- 催发货 -->
    <DialogSuccess
      title="催发成功"
      text="供应商将尽快处理发货"
      v-if="dialog_urge_goods"
      @close='dialog_urge_goods = false'
      @confirm='dialog_urge_goods = false'
    />
  </Layout>
</template>

<script>
import { OrderDetailApi,PeriodPayLogApi,RemindSendApi, receiveOrderApi, orderContractUploadApi } from "@/request/api/order.js"
export default {
  data() {
    return {
      routes: [
        { name: '首页', link: '/'},
        { name: '我的订单', link: '/order'},
        { name: '全部订单', link: '/order'},
        { name: '订单详情页', link: '' }
      ],
      currentStep: 2,
      order_id: "",
      detailInfo: {
        steps: {},
        send_logs:[]
      },
      periodPayLogList: [],
      tips: '交易已成功，如果买家提出售后要求，请积极与买家协商，做好售后服务。',
      dialog_delete: false,
      dialog_cancel: false,
      dialog_confirm: false,
      dialog_evaluate: false,
      dialog_complaint: false,
      dialog_send_goods: false,
      dialog_send_goods_msg: false,
      dialog_prod_feedback: false,
      dialog_urge_goods: false,

      uploadFiles: [],
      licenseImgList: []
    };
  },
  computed: {

  },
  created() {

  },
  mounted() {
    this.order_id = this.$route.query.order_id;
    this.getDetailInfo();
  },
  methods: {
    //详情
    getDetailInfo(){
      OrderDetailApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.detailInfo = res.data;

          this.licenseImgList.push(this.detailInfo.projectInfoItemsLicenseImg);
          this.currentStep = this.detailInfo.steps.current - 1;
          this.getPeriodPayLog();
        }
      })
    },
    //还款记录
    getPeriodPayLog(){
      PeriodPayLogApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.periodPayLogList = res.data.list;
        }
      })
    },
    //跳转到送货单
    toDeliveryNote(){
      this.$router.push({
        path: "/delivery-note",
        query: {
          order_id: this.order_id
        }
      })
    },
    //跳转立即付款
    toPay(){
      this.$router.push({
        path: "/pay",
        query: {
          order_id: this.order_id,
          merge_order_sn: this.detailInfo.merge_order_sn
        }
      })
    },
     //催发货
     toUrgeGoods(item){
      this.currentOrder = item;
      RemindSendApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.dialog_urge_goods = true;
          this.getDetailInfo();
        }
      })
    },
    //取消订单
    toCancel(){
      this.dialog_cancel = true;
    },
    cancelConfirm(){
      this.dialog_cancel = false;
      this.getDetailInfo();
    },
      //确认收货
      toOrderReceive(){
      this.dialog_confirm = true;
    },
    receiveOrderConfirm(){
      receiveOrderApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.dialog_confirm = false;
          this.$message.success("已确认收货");
          this.getDetailInfo();
        }
      })
    },
    //去评价
    toEvaluate(){
      this.dialog_evaluate = true;
    },
    elevateConfirm(){
      this.dialog_evaluate = false;
      this.getDetailInfo();
    },
    //上传合同
    uploadContract(file){
      if(file.status == "ready"){
        let params = {
          order_id: this.order_id,
          file: file.raw
        }
        orderContractUploadApi(params).then(res => {
          console.log(res);
        })
      }
    },
    confirmComplaint(){
      this.getDetailInfo();
      this.dialog_complaint = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './detail.scss';
</style>